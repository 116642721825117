import {
  Box,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout>
      <Container maxWidth="md" sx={{ my: 3 }}>
        <Typography variant="h5">Description</Typography>

        <Typography sx={{ my: 1 }}>
          Radoku creates tunnels through internet to target servers.
        </Typography>

        <StaticImage src="../images/radoku.png" alt="Radoku communications" />

        <Typography sx={{ my: 1 }}>
          <Typography color="primary" component="span">
            Only Radoku client needs to be able to access the target server
          </Typography>{" "}
          but{" "}
          <Typography color="secondary" component="span">
            a created tunnel gets a public internet address so that any client
            that knows the address can connect to the target server
          </Typography>
          .
        </Typography>

        <Typography variant="h5" sx={{ mt: 3 }}>
          Download Radoku client executable
        </Typography>

        <Table aria-label="downloads" sx={{ my: 1 }}>
          <TableHead>
            <TableRow>
              <TableCell>Target</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Linux x86_64</TableCell>
              <TableCell>0.8.0-beta</TableCell>
              <TableCell>
                <Link href="/downloads/radoku-client/0.8.0-beta/linux-x86_64/radoku">
                  Download for Linux
                </Link>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>macOS x86_64</TableCell>
              <TableCell>0.8.0-beta</TableCell>
              <TableCell>
                <Link href="/downloads/radoku-client/0.8.0-beta/macos-x86_64/radoku">
                  Download for macOS
                </Link>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Windows x86_64</TableCell>
              <TableCell>0.8.0-beta</TableCell>
              <TableCell>
                <Link href="/downloads/radoku-client/0.8.0-beta/windows-x86_64/radoku.exe">
                  Download for Windows
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant="h5" sx={{ mt: 3 }}>
          Start using tunnels
        </Typography>

        <Typography variant="subtitle1" sx={{ my: 1 }}>
          Create a tunnel to your local HTTP server with request replay support
        </Typography>

        <Box sx={{ p: 1, backgroundColor: "grey.200", borderRadius: 2 }}>
          <Typography color="secondary" component="span">
            radoku
          </Typography>{" "}
          <Typography color="primary" component="span">
            http
          </Typography>{" "}
          <Typography color="secondary" component="span">
            http://localhost:8080
          </Typography>
        </Box>

        <Typography variant="subtitle1" sx={{ my: 1 }}>
          Create a tunnel to your local HTTPS server with request replay support
        </Typography>

        <Box sx={{ p: 1, backgroundColor: "grey.200", borderRadius: 2 }}>
          <Typography color="secondary" component="span">
            radoku
          </Typography>{" "}
          <Typography color="primary" component="span">
            http
          </Typography>{" "}
          <Typography color="secondary" component="span">
            https://localhost:8443
          </Typography>
        </Box>

        <Typography variant="subtitle1" sx={{ my: 1 }}>
          Create a tunnel to your local TCP server
        </Typography>

        <Box sx={{ p: 1, backgroundColor: "grey.200", borderRadius: 2 }}>
          <Typography color="secondary" component="span">
            radoku
          </Typography>{" "}
          <Typography color="primary" component="span">
            tcp
          </Typography>{" "}
          <Typography color="secondary" component="span">
            tcp://localhost:25565
          </Typography>
        </Box>

        <Typography variant="subtitle1" sx={{ my: 1 }}>
          Create a tunnel to your local server that uses TLS
        </Typography>

        <Box sx={{ p: 1, backgroundColor: "grey.200", borderRadius: 2 }}>
          <Typography color="secondary" component="span">
            radoku
          </Typography>{" "}
          <Typography color="primary" component="span">
            tcp
          </Typography>{" "}
          <Typography color="secondary" component="span">
            tls://localhost:8443
          </Typography>
        </Box>

        <Typography variant="subtitle1" sx={{ my: 1 }}>
          Serve files from your computer
        </Typography>

        <Box sx={{ p: 1, backgroundColor: "grey.200", borderRadius: 2 }}>
          <Typography color="secondary" component="span">
            radoku
          </Typography>{" "}
          <Typography color="primary" component="span">
            files
          </Typography>{" "}
          <Typography color="secondary" component="span">
            directory/
          </Typography>
        </Box>
        <Typography variant="h5" sx={{ mt: 3 }}>
          Feedback and support
        </Typography>

        <Typography sx={{ my: 1 }}>
          You can send your feedback and questions to{" "}
          <Link href="mailto:support@radoku.com">support@radoku.com</Link>.
        </Typography>
      </Container>
    </Layout>
  );
};

export default IndexPage;
